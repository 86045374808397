@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

$fontsize: 28px, 24px, 20px, 18px, 16px, 14px, 12px;
$black: #414141;

html,
body {
  font-family: "Open Sans", "Noto Sans JP", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

@for $i from 1 through 6 {
  h#{$i} {
    margin: 0;
    padding: 0;
    color: $black;
    overflow-wrap: break-word;
    line-height: 1.5;
    font-size: nth($fontsize, $i);
  }
}

p,
a,
span {
  font-size: nth($fontsize, 5);
  color: $black;
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $black;
  display: block;
}

p {
  white-space: pre-line;
}
