@import "../styles/variables.scss";

.container {
  position: relative;
  z-index: 3;
  .bg {
    width: 100vw;
    height: 500px;
    position: absolute;
    top: -40px;
    left: 0;
    object-fit: cover;
  }
  .contact {
    padding: 0 20px 20px;
    text-align: center;
    position: relative;
    z-index: 4;
    .title {
      font-weight: 400;
      letter-spacing: 1px;
    }
    .section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 640px;
      margin: 20px auto;
      @media (max-width: 500px) {
        display: block;
        margin: 0 auto 20px;
      }
      .name {
        width: 120px;
        margin: 10px 20px;
        text-align: left;
        @media (max-width: 500px) {
          margin: 0 20px;
        }
      }
      .input {
        flex: 1;
        @media (max-width: 500px) {
          transform: scale3d(0.9, 0.9, 0.9);
        }
        .text {
          padding: 8px 10px;
          margin: 6px 0;
          width: 100%;
          border-radius: 4px;
          position: relative;
          background-color: #f4f4f4;
          border: 1px solid #f4f4f4;
          min-height: 40px;
          text-align: left;
        }
        .area {
          min-height: 120px;
        }
      }
    }
    .button {
      margin: 20px auto 0;
      max-width: 240px;
      padding: 6px 20px 8px;
    }
    .message {
      margin: 10px auto;
      font-size: 12px;
      position: absolute;
      left: 0;
      right: 0;
      color: #626262;
    }
  }
  .copyright {
    background: linear-gradient(140deg, #867df4, #649ced);
    width: 100%;
    position: absolute;
    bottom: -80px;
    text-align: center;
    .text {
      color: $white;
      padding: 20px 0;
      font-weight: 400;
      letter-spacing: 1px;
      cursor: pointer;
    }
  }
}
