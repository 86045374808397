@import "../styles/variables.scss";

.container {
  cursor: pointer;
  position: relative;
  padding: 10px 48px 12px;
  letter-spacing: 1px;
  display: flex;
  flex: 1;
  max-width: 480px;
  border-radius: 6px;
  margin: 16px auto;
  font-weight: 700;
  justify-content: center;
  border: 1px solid $link;
  color: $white;
  background-color: $link;
  box-shadow: 0 3px 6px $border;
  &:hover,
  &:active {
    color: $white;
    box-shadow: 0 3px 6px $border;
    opacity: 0.9;
  }
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: $white;
  }
  &.outlined {
    border-color: $link;
    background-color: $white;
    color: $link;
    &:hover,
    &:active {
      opacity: 1;
      background-color: $link-hover;
    }
  }
  .loading {
    background-color: $link;
    opacity: 1;
  }
  .error {
    position: absolute;
    font-size: 12px;
    color: $gray;
    top: calc(100% + 4px);
    letter-spacing: 0;
    padding: 2px 0;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
  }
}
