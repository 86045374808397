@import "../styles/variables.scss";

.container {
  padding: 0 40px;
  position: relative;
  overflow-x: hidden;
  transform: translateY(-60px);
  .bg {
    background-image: url("../../public/assets/bg/pc.svg");
    background-repeat: repeat-x;
    background-size: cover;
    position: absolute;
    top: -50%;
    left: 0;
    right: 0;
    min-width: 100%;
    min-height: calc(600px + 50%);
  }
  .button {
    box-shadow: none;
    max-width: 420px;
    margin-left: 0;
  }
  .over {
    max-width: $desktop;
    margin: 0 auto;
    display: flex;
    .left {
      position: relative;
      margin: 120px 30px 200px;
      flex: 1;
      .title {
        margin: 20px 0;
        font-size: 47px;
        line-height: 1;
        letter-spacing: 1px;
        color: $white;
      }
      .text {
        margin: 40px 0;
        font-size: 16px;
        line-height: 1.5;
        color: $white;
        font-weight: 400;
        max-width: 420px;
      }
    }
    .right {
      border-radius: 6px;
      transform: translateX(200px);
      padding: 140px 0 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .image {
        padding: 10px;
        background-color: $white;
        flex: 1;
        border-radius: 30px;
        position: absolute;
        right: 0;
        transition: all 0.5s ease;
        opacity: 1;
        object-fit: cover;
        box-shadow: 15px 44px 108px rgba(#3159d3, 0.12);
        &.hide {
          opacity: 0;
        }
      }
      .wrapper {
        position: absolute;
        right: 300px;
        bottom: 60px;
        display: flex;
        align-items: center;
        .dot {
          background-color: $white;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          margin: 2px 12px;
          box-shadow: $button-shadow;
          cursor: pointer;
          &.active {
            background-color: $link;
          }
        }
      }
    }
  }
}
