@import "../styles/variables.scss";

$max-width: 600px;
.container {
  padding: 0 30px 100px;
  position: relative;
  overflow-x: hidden;
  transform: translateY(-60px);
  .bg {
    background-image: url("../../public/assets/bg/mobile.svg");
    background-repeat: repeat-x;
    background-size: cover;
    position: absolute;
    top: calc(-1720px + 60%);
    left: 0;
    height: 2000px;
    width: 1100px;
    overflow-x: hidden;
  }
  .over {
    max-width: $max-width;
    margin: 10px auto;
    padding: 60px 0;
    .top {
      position: relative;
      padding: 10px 0;
      flex: 1;
      .title {
        margin: 20px 0;
        font-size: 40px;
        line-height: 1;
        letter-spacing: 1px;
        color: $white;
      }
      .text {
        margin: 40px 0;
        line-height: 1.8;
        color: $white;
        font-weight: 400;
      }
    }
    .bottom {
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 56.25vw;
      @media (min-width: calc(#{$max-width} + 60px)) {
        height: calc(#{$max-width} * 0.5625 + 40px);
      }
      .image {
        padding: 10px;
        background-color: $white;
        flex: 1;
        border-radius: 30px;
        position: absolute;
        top: 0;
        transition: all 0.5s ease;
        opacity: 1;
        object-fit: cover;
        max-width: $max-width;
        &.hide {
          opacity: 0;
        }
      }
    }
    .wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -2px;
      .dot {
        background-color: $white;
        border: 1px solid $white;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 12px 8px -4px;
        cursor: pointer;
        &.active {
          background-color: transparent;
        }
      }
    }
  }
  .button {
    box-shadow: none;
    margin: 30px auto 0;
  }
}
