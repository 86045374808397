$mobile: 800px;
$desktop: 1080px;

$link: #4a90e2;
$link-hover: #eaf2fb;
$red: #fc7888;
$orange: #fdaf7f;

$white: #fff;
$gray: #848484;
$lightgray: #b6b6b6;
$navy: #051441;
$lightnavy: #677294;
$lightgray-hover: rgba($lightgray, 0.15);
$border: #d9d9d9;
$bg: #fcfcfc;

$button-shadow: 0 2px 3px 0 rgba($lightgray, 0.5);
$card-shadow: 0 3px 6px 0 rgba($lightgray, 0.5);
