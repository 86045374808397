@import "../styles/variables.scss";

.container {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: $desktop;
  z-index: 2;
  margin: 0 auto;
  position: relative;
  .left {
    flex-shrink: 0;
    flex: 1;
    padding: 8px 0 2px;
    .logo {
      padding: 0 5px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    padding: 4px 10px;
    position: relative;
    cursor: pointer;
    .icon {
      margin: 4px 8px 0 8px;
      color: $gray;
    }
    .round {
      border-radius: 4px;
    }
    .name {
      margin: 0 4px;
    }
    .link {
      margin: 0 16px;
      color: $white;
      font-size: 12px;
    }
    @media (max-width: $mobile) {
      display: none;
    }
  }
  .modal {
    margin: 0 20px 60px;
    background-color: $bg;
    padding: 40px 20px;
    border-radius: 10px;
    .title {
      text-align: center;
    }
    .text {
      margin: 40px 0 30px;
    }
    .input {
      width: 400px;
      margin: 20px auto 0;
    }
    .button {
      min-width: 400px;
      border-width: 1px;
    }
    .bottom {
      text-align: center;
      padding: 10px 0;
    }
  }
}

.logo {
  .cls1 {
    fill: #3e3a39;
  }
  .cls2 {
    fill: #717071;
  }
  .cls3 {
    fill: #85baff;
  }
  .cls4 {
    fill: #989cff;
  }
  .cls5 {
    fill: #e3e16b;
  }
  .cls6 {
    fill: #fd6878;
  }
  .cls7 {
    fill: #fdb768;
  }
  .cls8 {
    fill: #fd9168;
  }
}
