@import "../styles/variables.scss";

$primary: #e32a30;

.container {
  border-radius: 4px;
  margin: 4px 0;
  width: 100%;
  position: relative;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  textarea {
    outline: none;
    -webkit-appearance: none;
    resize: none;
    border: none;
    overflow: hidden;
    box-shadow: none;
    background: transparent;
    appearance: none;
    width: 100%;
    min-height: 120px;
    font-family: "Open Sans", "Noto Sans JP", sans-serif;
    font-size: 16px;
    padding: 8px;
    &::-webkit-input-placeholder {
      font-family: "Open Sans", "Noto Sans JP", sans-serif;
    }
    &:-moz-placeholder {
      font-family: "Open Sans", "Noto Sans JP", sans-serif;
    }
  }
  input {
    outline: none;
    -webkit-appearance: none;
    border: none;
    overflow: hidden;
    box-shadow: none;
    background: transparent;
    appearance: none;
    width: 100%;
    font-family: "Open Sans", "Noto Sans JP", sans-serif;
    font-size: 16px;
    padding: 8px;
    &::-webkit-input-placeholder {
      font-family: "Open Sans", "Noto Sans JP", sans-serif;
    }
    &:-moz-placeholder {
      font-family: "Open Sans", "Noto Sans JP", sans-serif;
    }
  }
  &:hover,
  &:active {
    border: 1px solid $gray;
  }
  &:hover {
    border-color: $gray;
  }
  &.invalid {
    border-color: $primary;
  }
  .length {
    position: absolute;
    right: 2px;
    top: calc(100% + 2px);
    color: $gray;
    font-size: 12px;
  }
  .message {
    position: absolute;
    left: 2px;
    top: calc(100% + 2px);
    font-size: 12px;
    color: $primary;
  }
}
