@import "./styles/variables.scss";

.container {
  background-color: $white;
  height: 100%;
  .headline {
    text-align: center;
    max-width: $desktop;
    margin: 0 auto 20px;
    padding: 0 20px;
    max-width: 640px;
    @media (max-width: 1079px) {
      &.top {
        margin-top: -100px;
      }
    }
    .top {
      text-align: center;
      padding: 10px 0;
      line-height: 2;
      .title {
        margin: 30px 0 10px;
        font-size: 32px;
        letter-spacing: 1px;
      }
      .text {
        margin: 20px auto;
        color: $gray;
        line-height: 2;
        white-space: pre-line;
        max-width: 840px;
      }
    }
  }
  .section1 {
    position: relative;
    max-width: $desktop;
    margin: 0 auto 160px;
    .content {
      border-radius: 10px;
      box-shadow: $card-shadow;
      margin: 0 20px;
      display: flex;
      align-items: center;
      @media (max-width: $mobile) {
        display: block;
        text-align: center;
        padding: 10px 0;
      }
      .left {
        flex: 1;
        text-align: center;
        padding: 20px 30px;
        margin: 10px;
        background-color: $bg;
      }
      .logo {
        margin: 20px 0;
      }
      .right {
        margin: 10px;
        flex: 1;
        padding: 0 30px 10px;
        position: relative;
        .title {
          text-align: left;
          white-space: pre-line;
          @media (max-width: $mobile) {
            text-align: center;
          }
        }
        .text {
          color: $gray;
          text-align: left;
          margin: 20px 0;
          line-height: 2;
          @media (max-width: $mobile) {
            text-align: center;
          }
        }
      }
    }
  }
  .section2 {
    display: flex;
    max-width: $desktop;
    margin: 0 auto 100px;
    align-items: center;
    &.reverse {
      flex-direction: row-reverse;
    }
    @media (max-width: $mobile) {
      display: block;
      text-align: center;
      padding: 10px;
      box-shadow: $card-shadow;
      background-color: $white;
      margin: 0 20px 40px;
      border-radius: 8px;
    }
    .left {
      flex: 1;
      padding: 0 4%;
      text-align: center;
      position: relative;
      .title {
        margin: 10px 0;
        font-weight: 400;
      }
      .logo {
        transform: translateX(-20px);
        margin-bottom: -20px;
        @media (max-width: $mobile) {
          transform: translateY(20px);
        }
      }
      .text {
        color: $gray;
        line-height: 2;
        text-align: left;
        margin: 20px 0;
        @media (max-width: $mobile) {
          text-align: center;
        }
      }
    }
    .right {
      flex: 1;
      text-align: left;
      padding: 10px 4%;
      position: relative;
      .youtube {
        position: absolute;
        bottom: -20px;
        right: -40px;
        &:hover,
        &:active {
          transform: scale3d(1.05, 1.05, 1.05);
        }
      }
    }
    .image {
      text-align: center;
      position: relative;
      max-width: 420px;
      margin: 20px auto 0;
      .logo {
        padding: 20px;
        box-shadow: 14px 41px 100px rgba(#3159d3, 0.12);
        background-color: $white;
        border-radius: 30px;
        @media (max-width: $mobile) {
          margin-bottom: 30px;
          box-shadow: 6px 11px 20px rgba(#3159d3, 0.12);
        }
      }
    }
    .candy1 {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $red;
      position: absolute;
      top: -10px;
      left: -30px;
    }
    .candy2 {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $orange;
      position: absolute;
      right: 20px;
      bottom: -80px;
    }
  }
  .section3 {
    padding: 0 20px;
    text-align: center;
    max-width: $desktop;
    margin: 0 auto;
    .member {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @media (max-width: $mobile) {
        flex-wrap: wrap;
      }
      .card {
        position: relative;
        margin: 0 10px 30px;
        cursor: pointer;
        background-color: rgb(230, 233, 239);
        width: 243px;
        height: 360px;
        overflow: hidden;
        .photo {
          object-fit: contain;
          object-position: center 20%;
        }
        .bottom {
          position: absolute;
          background-color: rgba($white, 0.95);
          bottom: 0;
          left: 0;
          right: 0;
          padding: 10px;
          .name {
            letter-spacing: 1px;
          }
          .carrer {
            color: $gray;
            margin: 4px 0;
            font-size: 12px;
          }
        }
        &:before {
          z-index: 1;
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          background: linear-gradient(140deg, #867df4, #649ced);
          transition: all 0.3s ease;
          opacity: 0;
        }
        &:hover,
        &:active {
          &:before {
            opacity: 0.95;
          }
        }
        .bg {
          position: absolute;
          top: 0;
          padding: 10px;
          z-index: 2;
          width: 100%;
          height: 100%;
          opacity: 0;
          overflow-y: scroll;
          &:hover,
          &:active {
            opacity: 1;
          }
          .description {
            color: $white;
            line-height: 2;
            font-size: 12px;
            display: flex;
            align-items: center;
          }
          .sns {
            display: flex;
            justify-content: center;
            min-height: 20px;
            .icon {
              width: 24px;
              height: 24px;
              margin: 10px;
            }
          }
          .name {
            letter-spacing: 1px;
            color: $white;
            font-weight: 400;
            margin: 4px 0;
          }
          .carrer {
            color: $white;
            font-size: 12px;
            margin: 4px 0;
          }
        }
      }
      .placeholder {
        position: relative;
        margin: 0 10px;
        cursor: pointer;
        background-color: rgb(230, 233, 239);
        width: 243px;
        height: 360px;
        @media (min-width: calc(#{$mobile} + 1px)) and (max-width: 1100px) {
          display: none;
        }
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .plus {
          font-size: 40px;
          margin-bottom: 60px;
          color: $gray;
        }
        .bottom {
          position: absolute;
          background-color: rgba($white, 0.95);
          bottom: 0;
          left: 0;
          right: 0;
          padding: 12px 0 14px;
          border: 1px solid $bg;
          .name {
            padding: 8px 0;
            letter-spacing: 1px;
            color: $gray;
          }
        }
      }
    }
  }
  .section4 {
    padding: 0 20px 20px;
    text-align: center;
    .map {
      position: relative;
      z-index: 1;
      .address {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        max-width: $desktop;
        margin: 0 auto;
        opacity: 0.95;
        .card {
          background-color: $white;
          width: 290px;
          z-index: 3;
          padding: 10px 20px;
          border-radius: 6px;
          margin: 10px;
          border: 1px solid $border;
          .name {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid $bg;
            color: $navy;
          }
          .detail {
            padding: 10px;
            .text {
              color: $lightnavy;
              text-align: left;
              margin: 4px 0;
              font-size: 12px;
            }
            a {
              text-decoration: underline;
              display: flex;
            }
          }
        }
      }
    }
  }
}
